import { yupResolver } from '@hookform/resolvers/yup'
import { FormModel } from '../models'
import { schema } from './schema'

const { name, phone, city, country, description, email } = new FormModel('', '', '', '', '', '')

export const options = {
  mode: 'onSubmit',
  resolver: yupResolver(schema),
  defaultValues: { name, phone, city, email, country, description },
}
