import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Variants } from '../../animations'
import { Box, Typography } from '@material-ui/core'
import { Dialog } from './Dialog'

const { inViewImg, inViewBage, container } = Variants

export const Reveal = ({ img, name, data, id }) => {
  const controls = useAnimation()
  const { ref, inView } = useInView({ threshold: 0 })

  const [open, setOpen] = useState(false)
  const [dialogContent, setDialogContet] = useState(null)

  const handleClickOpen = (id) => {
    const currentImg = data.find((item) => item.id === id)
    setDialogContet(currentImg)
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <Box className="container" component={motion.div} variants={container} initial="hidden" animate="show">
      <motion.img
        initial="hidden"
        animate={controls}
        variants={inViewImg()}
        ref={ref}
        onClick={() => handleClickOpen(id)}
        className="photo"
        src={img}
        alt="example"
        loading="lazy"
      />
      {name && (
        <Box
          component={motion.div}
          className="badge"
          ref={ref}
          variants={inViewBage()}
          initial="hidden"
          animate={controls}
          exit="exit"
        >
          <Typography component="p">{name}</Typography>
        </Box>
      )}
      {open && <Dialog open={open} onClose={handleClose} content={dialogContent} />}
    </Box>
  )
}
