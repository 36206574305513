import { memo } from 'react'

export const Map = memo(({ id }) => {
  return (
    <>
      <iframe
        title="map"
        src={`https://www.google.com/maps/d/u/0/embed?mid=${id}`}
        frameBorder="0"
        width="100%"
        height="100%"
      />
    </>
  )
})
