// Core
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
//Mui
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
// Styles
import { Nav } from '../../styles'
// Components
import { Drawer } from '../Navigation'
import { LangSelect } from '../Ui'
// Other
import Logo from '../../assets/logo/logo.png'

export const Navigation = () => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isMedium = useMediaQuery(breakpoints.down('md'))
  const toTop = () => window.scroll({ top: 0, behavior: 'smooth' })
  return (
    <Nav>
      <img src={Logo} alt="logo" className="logo" title="Лого" onClick={toTop} />
      {!isMedium ? (
        <Typography component="div" className="links">
          <Typography component={NavLink} variant="button" to="/home" end="true">
            {t('nav.main')}
          </Typography>
          <Typography component={NavLink} variant="button" to="/products">
            {t('nav.production')}
          </Typography>
          <Typography component={NavLink} variant="button" to="/in-use">
            {t('nav.porfolio')}
          </Typography>
          <Typography component={NavLink} variant="button" to="/documents" end="true">
            {t('nav.docs')}
          </Typography>
          <Typography component={NavLink} variant="button" to="/about-us">
            {t('nav.about')}
          </Typography>
          <Typography component={NavLink} variant="button" to="/sales">
            {t('nav.dealers')}
          </Typography>
          <Typography component={NavLink} variant="button" to="/contacts">
            {t('nav.contacts')}
          </Typography>
        </Typography>
      ) : (
        <Drawer />
      )}
      <LangSelect />
    </Nav>
  )
}
