import styled from 'styled-components'

export const Footer = styled.footer`
  display: flex;
  flex: 1 1 auto;
  background: #d3d3d3;
  align-items: baseline;
  justify-content: space-between;
  padding: 0rem 4rem;
  border-top: 2px solid #331d0b;
  position: relative;
  z-index: 1100;
  .footer-copy {
    display: inline-flex;
    color: #462810;
    p {
      font-size: 1rem;
      font-weight: 900;
      white-space: nowrap;
    }
    span {
      padding-left: 0.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0rem 0rem 0rem;
    display: flex;
    align-items: center;
    li {
      align-items: center;
      line-height: 0;
      &:nth-child(2) {
        padding: 0 0 0 1rem;
      }
      a {
        color: #331d0b;
        span {
          svg {
            font-size: 2rem;
          }
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0rem 2rem;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    .footer-copy {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-size: 4vw;
      }
    }
  }
`
