import styled from 'styled-components'
import { Box } from '@material-ui/core'
import MuiDialog from '@material-ui/core/Dialog'

export const Documents = styled(Box)`
  padding: 0rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;
  .title {
    font-weight: bold;
    padding: 2rem 0rem 1rem 0rem;
    color: #5f3717;
    z-index: 200;
  }
  .logo {
    position: fixed;
    z-index: 100;
    height: 100%;
    width: 100%;
    right: -50vw;
    opacity: 0.05;
  }
  .certificates {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 1rem 0rem;
    z-index: 200;
    &__docs {
      height: 70vh;
      z-index: 200;
      position: relative;
      padding: 1rem;
      background: linear-gradient(to bottom, #e7e7e7 50%, #e7e7e7 50%, #d3d3d3 75%, #d3d3d3 75%);
      box-shadow: inset 0px 0px 10px 2px rgba(182 182 182 / 80%);
      z-index: 200;
      &-description {
        font-family: 'Roboto';
        text-align: center;
        border-bottom: 2px solid rgba(182 182 182 / 80%);
        width: 100%;
        background: #f1f1f1;
        font-weight: bold;
        opacity: 0;
        transition: all 0.3s linear;
        color: #5f3717;
        position: absolute;
        bottom: 15px;
        padding: 0.5rem 0rem;
        margin: -1rem;
        z-index: 200;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        cursor: pointer;
        z-index: 200;
      }
      &:hover .certificates__docs-description {
        opacity: 1;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0rem 2rem;
    h3 {
      font-size: 2rem;
    }
    .certificates {
      gap: 1rem;
      padding: 1rem;
      &__docs {
        height: 60vh;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0rem 1rem;
    .title {
      font-size: 1.8rem;
    }
    .certificates {
      padding: 0.5rem;
      &__docs {
        height: 75vh;
      }
    }
    ${({ theme }) => theme.breakpoints.down('xs')} {
      .certificates {
        padding: 0.5rem;
        &__docs {
          height: 65vh;
        }
      }
    }
  }
`
export const Modal = styled(MuiDialog)`
  .detail {
    width: 50vw;
    h6 {
      ${({ theme }) => theme.breakpoints.down('xs')} {
        font-size: 1rem;
      }
    }
    .modal-content {
      .slick-slider {
        height: 100%;
        ${({ theme }) => theme.breakpoints.down('xs')} {
          height: 85vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .inUse {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }
    }
    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 80vw;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 90vw;
    }
    ${({ theme }) => theme.breakpoints.down('xs')} {
      width: auto;
    }
  }
`
