import { Box } from '@material-ui/core'
import styled from 'styled-components'
import Slider from 'react-slick'

export const Wrapper = styled(Box)`
  min-height: 85vh;
  .tabs {
    display: flex;
    justify-content: center;
  }
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      padding-top: 0.5rem;
      font-size: 2.5rem;
      font-weight: bold;
      color: #331d0b;
    }
    .slick-slider {
      width: 80vw;
      height: 100%;
      z-index: 200;
      .slick-prev,
      .slick-next {
      }
    }
    .preview {
      margin-top: 1rem;
      border: 4px solid #331d0b;
      z-index: 200;
      min-height: 588px;
      max-height: 294px;
      width: 65vw;
      overflow: hidden;
      iframe {
        min-height: 588px;
        max-height: 294px;
        height: 100%;
        width: 100%;
        object-fit: initial;
        background: #000;
      }
      img {
        min-height: 588px;
        max-height: 294px;
        height: 100%;
        width: 100%;
        background: #331d0b;
        object-fit: cover;
      }
    }
    .content {
      display: flex;
      padding: 2rem 6rem 0rem 0rem;
      position: relative;
      &-left {
        display: flex;
        padding-left: 2rem;
        flex-direction: column;
        align-items: center;
        &__logo {
          position: fixed;
          top: 30vh;
          height: 35vh;
          left: 1rem;
          z-index: 100;
          opacity: 0.035;
        }
        &__title {
          color: #331d0b;
          flex: 1 1 auto;
          width: 100%;
          font-size: 3rem;
          z-index: 200;
          text-align: center;
        }
      }
      &-right {
        padding: 1rem 12rem 1rem 2rem;
        z-index: 200;
        color: #331d0b;
        p {
          font-size: 1.3rem;
          &:nth-child(1n) {
            padding: 0rem 1rem 1rem 0rem;
          }
        }
      }
    }
    .product-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
    }
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    .wrapper {
      .slick-slider {
        padding-left: 0;
      }
      .content {
        &-left {
          padding-left: 4rem;
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .wrapper {
      .preview {
        width: 90vw;
        min-height: 440px;
        iframe,
        img {
          min-height: 440px;
        }
      }
      .content {
        &-left {
          padding-left: 5rem;
          &__title {
            font-size: 2rem;
            padding: 0.5rem 0rem;
          }
          &__logo {
            top: 40vh;
            left: 0;
            height: 400px;
            width: 100%;
            overflow: hidden;
            z-index: 1;
          }
        }
        &-right {
          padding: 1rem 1rem 0rem 1rem;
          p {
            font-size: 1.2rem;
          }
        }
      }
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      .wrapper {
        .slick-slider {
          width: 85vw;
        }
        .preview {
          width: 90%;
        }
        .content {
          flex-direction: column;
          padding: 0.5rem 1rem;
          align-items: center;
          text-align: center;

          &-left {
            padding-left: 0;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__title {
              padding: 0.5rem 0rem;
              font-size: 1.5rem;
            }
          }
          &-right {
            padding: 0rem 1rem;
            p {
              font-size: 1rem;
              &:nth-child(1n) {
                padding: 0.5rem;
              }
            }
          }
        }
      }
    }
    ${({ theme }) => theme.breakpoints.down('xs')} {
      .wrapper {
        .preview {
          width: 90vw;
          min-height: 240px;
          iframe,
          img {
            min-height: 290px;
          }
        }
        .content {
          &-right {
            padding: 0;
          }
        }
      }
    }
  }
`

export const Carsousel = styled(Slider)`
  display: flex;
  width: 100%;
  height: 100%;
  .produce-card {
    display: flex;
    flex-direction: column;
    color: #331d0b;
    background: #e1e1e1;
    margin: 2rem auto;
    width: 80%;
    border-radius: 0;
    z-index: 200;
    padding: 0rem 0.5rem;
    box-shadow: inset 0px 0px 10px 2px rgba(182 182 182 / 80%);
    &__title {
      font-style: italic;
    }
    &__copy {
      font-size: 1rem;
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .produce-card {
      width: 100%;
    }
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    .produce-card {
      margin: 1rem auto;
      &__title {
        font-size: 1.5rem;
      }
    }
  }
`
