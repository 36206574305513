import styled from 'styled-components'
import { Box } from '@material-ui/core'

export const HeroStyled = styled(Box)`
  display: flex;
  position: relative;
  height: 100vh;
  z-index: 1000;
  .homeImg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 282px;
  }
  .copy {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    padding: 0rem 4rem;
    color: #fff;
    line-height: 150%;
    width: 100%;
    h2 {
      white-space: nowrap;
      font-size: 3rem;
    }
    .copy1 {
      color: #b2d8f6;
      font-size: 1.2rem;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .copy {
      text-align: center;
      margin: 0 auto;
      padding: 2rem;
      .copy1 {
        padding: 0rem 0.5rem;
        font-size: 3vw;
        line-height: 200%;
      }
      h2 {
        font-size: 2rem;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    .copy {
      padding: 0rem 0.5rem;
      h2 {
        font-size: 6vw;
      }
    }
  }
`

export const Traditions = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  background: #f1f1f1;
  height: calc(78vh - 140px);
  ${({ theme }) => theme.breakpoints.down('sm')} {
    background: linear-gradient(to bottom, #f1f1f1 80%, #331d0b 20%);
  }
  .traditions-preview {
    margin: 0;
    z-index: 1050;
    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 100%;
    }
    img {
      object-fit: cover;
      height: 64vh;
      z-index: 1;
      ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 100%;
        height: 100%;
        padding: 1rem;
      }
    }
  }
  .traditions-copy {
    padding: 0 4rem;
    color: #462810;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    z-index: 900;
    &__title {
      font-weight: 800;
      font-size: clamp(1.5rem, 2vw, 3rem);
    }
    &__description {
      padding: 1rem 2rem 0rem 0rem;
      font-size: clamp(1rem, 1.5vw, 1.2rem);
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .traditions-copy {
      padding: 0 2rem;
      &__title {
        padding: 0.5rem;
      }
      &__description {
        padding: 0;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 100%;
    flex-direction: column-reverse;
    .traditions-preview {
      background: linear-gradient(to bottom, #f1f1f1 20%, #331d0b 80%);
    }
    .traditions-copy {
      padding: 0rem 1rem 1rem 1rem;
      &__title {
        text-align: center;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column-reverse;
    padding: 0rem 0rem;
    .traditions-preview {
      height: 50vh;
      img {
        padding: 0rem 0.5rem 0rem 0.5rem;
      }
    }
  }
`
export const About = styled(Box)`
  width: 100%;
  padding: 2rem 4rem;
  background: #331d0b;
  color: #fff;
  .about-copy {
    display: flex;
    flex-direction: column;
    &__title {
      line-height: 150%;
      font-weight: bold;
      opacity: 0.8;
      font-size: clamp(1.5rem, 4vw, 2rem);
    }
    &__description {
      opacity: 0.8;
      font-size: clamp(1rem, 2vw, 1.2rem);
      padding-top: 1rem;
      font-weight: 100;
    }
    &__actions {
      display: flex;
      align-items: flex-end;
      button {
        margin: 1rem 0rem 0rem auto;
        background: #f1f1f1;
        border: 2px solid transparent;
        span {
          padding: 0rem 0.8rem;
        }
        &:hover {
          background: transparent;
          border-color: #f1f1f1;
          color: #f1f1f1;
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 2rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0rem 1rem;
    .about-copy {
      &__title {
        padding-top: 1rem;
        text-align: center;
      }
      &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          margin: 1rem 0rem 1rem 0rem;
          flex: 1 auto;
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0rem 1rem;
    .about-copy {
      &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          margin: 1rem 0rem 1rem 0rem;
          flex: 1 auto;
        }
      }
    }
  }
`

export const Preview = styled(Box)`
  margin: 0;
  padding: 0;
  display: flex;
  background: linear-gradient(to bottom, #ffffff, #f1f1f1 30%, #d3d3d3 80%);
  .examples-preview {
    padding: 0;
    margin: 0;
    z-index: 100;
    height: 64vh;
    position: relative;
    width: 100%;
    .Carousel-root-13,
    .CarouselItem {
      height: inherit;
    }
    &__image {
      object-fit: cover;
      width: 100%;
      height: 75vh;
      position: absolute;
      top: -1rem;
    }
  }

  .examples-actions {
    justify-content: space-around;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 200;
    background: linear-gradient(to bottom, #f1f1f1, #dddddd, #d3d3d3);
    ${({ theme }) => theme.breakpoints.down('sm')} {
      background: linear-gradient(to bottom, #e0e0e0, #dddddd, #d3d3d3);
    }
    ${({ theme }) => theme.breakpoints.down('xs')} {
      background: linear-gradient(to bottom, #e5e5e5, #dddddd, #d3d3d3);
    }
    h3 {
      font-weight: bold;
      font-size: clamp(1.5rem, 10vw, 3rem);
      color: #462810;
      z-index: 400;
    }
    &__buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4rem;
      position: relative;
      z-index: 200;
      button {
        z-index: 200;
      }
      &-logo {
        width: 100%;
        display: flex;
        justify-content: center;
        width: 100%;
        .logo {
          height: 30vh;
          opacity: 0.05;
          z-index: 100;
        }
      }
    }
    &__detail {
      background: #331d0b;
      color: #f1f1f1;
      border: 2px solid transparent;
      z-index: 200;
      span {
        padding: 0rem 0.8rem;
      }
      &:hover {
        background: #f1f1f1;
        border-color: #331d0b;
        color: #331d0b;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .examples-preview {
      height: calc(68vh - 140px);
      &__image {
        height: 105%;
      }
    }
    .examples-actions__buttons {
      padding: 0;
      &-logo {
        .logo {
          height: 20vh;
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    .examples-preview {
      background: linear-gradient(to top, transparent, #331d0b 80%);
      top: 0;
      height: 58vh;
      &__image {
        width: 100%;
        height: 100%;
        padding: 1rem 1rem;
        z-index: 200;
      }
      h3 {
        margin: 2rem 0rem;
      }
      a {
        margin: 2rem 0rem;
      }
    }
    .examples-actions {
      &__buttons {
        &-logo {
          margin: 1rem 0rem 2rem 0rem;
          .logo {
            height: 20vh;
          }
        }
      }
      &__detail {
        width: 94%;
        margin-bottom: 1rem;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    .examples-preview {
      height: 40vh;
      &__image {
        padding: 1rem 0.5rem 0rem 0.5rem;
      }
    }
  }
`
