import { lazy } from 'react'

export const Home = lazy(() => import('./Home'))
export const AboutUs = lazy(() => import('./AboutUs'))
export const Products = lazy(() => import('./Products'))
export const Contacts = lazy(() => import('./Contacts'))
export const Sales = lazy(() => import('./Sales'))
export const Documents = lazy(() => import('./Documents'))

export * from './Products'
