// import { useMediaQuery, useTheme } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion'
import { memo } from 'react'
import { Variants } from '../../animations'

export const Preview = memo(({ src }) => {
  const { reveal } = Variants
  return (
    <AnimatePresence key={src} exitBeforeEnter>
      <motion.div
        variants={reveal()}
        layoutId={`image-${src}`}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="preview"
      >
        <img src={src} alt="ek-brick" />
      </motion.div>
    </AnimatePresence>
  )
})
