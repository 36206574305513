//Core
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'
// Styles
import { theme } from './styles'
// Pages
import { Home, AboutUs, Contacts, ProductsPage, Portfolio, PortfolioById, Sales, Documents } from './pages'
//Components
import { Layout } from './components/Layout'
import { Suspense } from 'react'
import { Loader } from './components/Ui'

const App = () => {
  const { pathname } = useLocation()

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Routes key={pathname}>
          <Layout>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route
              path="/home"
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/products"
              element={
                <Suspense fallback={<Loader />}>
                  <ProductsPage />
                </Suspense>
              }
            />
            <Route
              path="/documents"
              element={
                <Suspense fallback={<Loader />}>
                  <Documents />
                </Suspense>
              }
            />
            <Route
              path="/in-use"
              element={
                <Suspense fallback={<Loader />}>
                  <Portfolio />
                </Suspense>
              }
            />
            <Route
              path="/in-use/:productId"
              element={
                <Suspense fallback={<Loader />}>
                  <PortfolioById />
                </Suspense>
              }
            />
            <Route
              path="/sales"
              element={
                <Suspense fallback={<Loader />}>
                  <Sales />
                </Suspense>
              }
            />
            <Route
              path="/about-us"
              element={
                <Suspense fallback={<Loader />}>
                  <AboutUs />
                </Suspense>
              }
            />
            <Route
              path="/contacts"
              element={
                <Suspense fallback={<Loader />}>
                  <Contacts />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate to="/home" />} />
          </Layout>
        </Routes>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
