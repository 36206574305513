//Core
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Mui
import { Typography, useMediaQuery, useTheme, IconButton, Button, Box } from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
// Styles
import { Content, Title, Dialog } from '../../styles'
// Components
import { Request } from './index'

const DialogTitle = ({ onClose, title }) => {
  return (
    <Title disableTypography>
      <Typography variant="body1">{title}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </Title>
  )
}
const DialogContent = memo(({ content, media }) => {
  return (
    <Content>
      <Box component="figure" className="content">
        <Box className="content-media">
          <img src={media} alt="detail" />
        </Box>
        <Box component="figcaption" className="content-info">
          {content.props && (
            <>
              <Typography gutterBottom>
                {content.props.format}:<Typography component="span"> {content.nf}</Typography>
                {content.wdf && <Typography component="span"> / {content.wdf}</Typography>}
                {content.wf && <Typography component="span"> / {content.wf}</Typography>}
              </Typography>
              <Typography gutterBottom component="p">
                {content.props.grade.title}
                <Typography component="span">{content.props.grade.value}</Typography>
              </Typography>
              <Typography gutterBottom component="p">
                {content.props.absorption.title}
                <Typography component="span">{content.props.absorption.value}</Typography>
              </Typography>
              <Typography gutterBottom component="p">
                {content.props.conductivity.title}
                <Typography component="span">{content.props.conductivity.value}</Typography>
              </Typography>
              <Typography gutterBottom component="p">
                {content.props.resistance.title}
                <Typography component="span">{content.props.resistance.value}</Typography>
              </Typography>
            </>
          )}
          {content.tileProps && (
            <>
              <Typography gutterBottom>
                {content.tileProps.format}:<Typography component="span"> {content.nf}</Typography>
                {content.wdf && <Typography component="span"> / {content.wdf}</Typography>}
                {content.wf && <Typography component="span"> / {content.wf}</Typography>}
              </Typography>
              <Typography gutterBottom component="p">
                {content.tileProps.absorption.title}
                <Typography component="span">{content.tileProps.absorption.value}</Typography>
              </Typography>
              <Typography gutterBottom component="p">
                {content.tileProps.resistance.title}
                <Typography component="span">{content.tileProps.resistance.value}</Typography>
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Content>
  )
})

const DialogAction = () => {
  return (
    <MuiDialogActions className="detail-action">
      <Request />
    </MuiDialogActions>
  )
}

export const Detail = memo((props) => {
  const { title, description, img } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const small = useMediaQuery(breakpoints.down('xs'))

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button onClick={handleClickOpen}>{t('detail')}</Button>
      <Dialog
        fullScreen={small}
        fullWidth={small}
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {open && (
          <Box className="detail">
            <DialogTitle onClose={handleClose} title={title} />
            <DialogContent content={description} media={img} />
            <DialogAction />
          </Box>
        )}
      </Dialog>
    </>
  )
})
