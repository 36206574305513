import styled from 'styled-components'

export * from './Home'
export * from './Products'
export * from './Projects'
export * from './About'
export * from './Sale'
export * from './Contacts'
export * from './Example'
export * from './Docs'

export const Main = styled.main`
  overflow: hidden;
  z-index: 1000;
  margin-top: 70px;
  background: #f1f1f1;
  background: linear-gradient(to bottom, #ffffff, #f1f1f1 50%, #d3d3d3 75%, #d3d3d3 75%);
`

export const Load = styled.div`
  background: linear-gradient(to bottom, #ffffff, #f1f1f1 50%, #d3d3d3 75%, #d3d3d3 75%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
