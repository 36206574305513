import axios from 'axios';

export const api = Object.freeze({
  sendRequest: (payload) => {
    axios.post(
      `https://api.telegram.org/bot/1976350416:AAGw9fxZNnLi3VZZy7DuIM4WlFmvYMVAJPg/sendMessage`,
      {
        chat_id: -458917609,
        text: payload,
        parse_mode: 'HTML',
      }
    );
  },
});
