export const Variants = Object.freeze({
  reveal: () => {
    return {
      visible: {
        opacity: 1,
        transition: {
          opacity: {
            delay: 0.2,
            duration: 0.8,
            type: 'tween',
            ease: 'easeOut',
          },
        },
      },
      hidden: { opacity: 0 },
    }
  },
  container: () => {
    return {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          staggerChildren: 1,
        },
      },
    }
  },
  inViewImg: () => {
    return {
      visible: {
        opacity: 1,
        transition: {
          type: 'tween',
          ease: 'easeIn',
          duration: 0.5,
        },
      },
      hidden: { opacity: 0 },
    }
  },
  inViewBage: () => {
    return {
      visible: {
        opacity: 1,
        x: '0%',
        transition: {
          type: 'tween',
          ease: 'easeOut',
          delay: 0.5,
          duration: 0.5,
        },
      },
      hidden: { opacity: 0, x: '80%' },
    }
  },
  show: () => {
    return {
      visible: {
        opacity: 1,
        transition: {
          opacity: {
            duration: 0.5,
            type: 'tween',
            ease: 'linear',
          },
        },
      },
      hidden: { opacity: 0 },
    }
  },
  hero: () => {
    return {
      visible: {
        opacity: 1,
        scale: 1,
        transition: {
          opacity: {
            duration: 0.5,
            type: 'tween',
            ease: 'linear',
          },
          scale: {
            delay: 0.1,
            duration: 0.5,
            type: 'tween',
            ease: 'easeOut',
          },
        },
      },
      hidden: { opacity: 0, scale: 1.5 },
    }
  },
  heroText: () => {
    return {
      visible: {
        opacity: 1,
        transition: {
          staggerChildren: 1.5,
        },
      },
      hidden: { opacity: 0 },
    }
  },
  heroCopy: () => {
    return {
      visible: {
        opacity: 1,
        transition: { delay: 0.5, duration: 0.5 },
      },
      hidden: { opacity: 0 },
    }
  },

  slideUp: () => {
    return {
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          y: {
            type: 'tween',
            ease: 'easeOut',
            duration: 0.7,
          },
          opacity: { duration: 0.3 },
        },
      },
      hidden: {
        y: '15%',
        opacity: 0,
      },
    }
  },

  slideLeft: (direction) => {
    return {
      hidden: {
        x: direction > 0 ? -250 : 250,
        opacity: 0,
        duration: 0.5,
      },
      visible: {
        x: 0,
        opacity: 1,
        transition: {
          x: {
            type: 'spring',
            staggerChildren: 0.35,
            mass: 0.35,
            duration: 0.5,
          },
          opacity: { duration: 0.25 },
        },
      },
      exit: {
        opacity: 0,
        duration: 0.25,
      },
    }
  },
})
