import { Box, Button } from '@material-ui/core'
import styled from 'styled-components'

export const StyledContacts = styled(Box)`
  display: flex;
  a {
    color: #331d0b;
    span {
      svg {
        font-size: 24px;
      }
    }
  }
  .contacts {
    position: relative;
    z-index: 200;
    display: flex;
    background: transparent;
    flex-direction: column;
    padding: 0rem 4rem;
    justify-content: center;
    flex: 1 0 auto;
    &-info {
      display: flex;
      flex-direction: column;
      background: #f1f1f1;
      width: 100%;
      z-index: 200;
      justify-content: center;
      padding: 1rem 2rem 1rem 2rem;
      box-shadow: inset 0px 0px 10px 2px rgba(182 182 182 / 80%);
      transition: 0.3s all ease-in-out;
      &:hover {
        box-shadow: inset 0px 0px 15px 2px rgba(182 182 182 / 90%);
      }
      &__title {
        font-weight: bold;
        padding: 1rem 0rem;
        color: #462810;
        font-size: 2rem;
        ${({ theme }) => theme.breakpoints.down('xs')} {
          font-size: 1.4rem;
        }
      }

      &__copy {
        display: flex;
        align-content: center;
        flex-direction: column;
        &-wrapper {
          display: inline-flex;
          flex-wrap: wrap;
          align-items: baseline;
          p {
            color: #462810;
            line-height: 150%;
            font-size: 1.2rem;
            font-weight: bold;
          }
          span {
            font-size: 1rem;
            vertical-align: middle;
            &:not(:first-child) {
              padding: 0rem 0.2rem;
            }
          }
        }
      }
    }
    &-action {
      justify-content: center;
      margin-top: 2rem;
      z-index: 200;
      button {
        background: #f1f1f1;
        padding: 0.5rem 2rem;
        border: 2px solid #331d0b;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: #f1f1f1;
          background: #331d0b;
          border-color: #f1f1f1;
        }
      }
    }
    img {
      position: absolute;
      height: 100%;
      z-index: 100;
      right: -12vw;
      opacity: 0.05;
      ${({ theme }) => theme.breakpoints.down('xs')} {
        right: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    .contacts {
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 2rem 1rem 2rem 1rem;
      &-info {
        width: 80%;
        &__title {
          padding: 0.5rem 0rem;
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    .contacts {
      &-info {
        width: 100%;
      }
    }
  }
`
export const StyledMap = styled(Box)`
  height: 90vh;
  width: 100%;
  margin: 0 auto;
  z-index: 200;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    height: 44vh;
  }
`

export const StyledRequestButton = styled(Button)`
  background: #331d0b;
  color: #f1f1f1;
  padding: 0.4rem 2.4rem;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #331d0b;
    background: transparent;
    border-color: #331d0b;
  }
  &:disabled {
    background: #331d0b;
    color: #f1f1f1;
    opacity: 0.5;
  }
`
