import styled from 'styled-components'
import { Box } from '@material-ui/core'

export const ExampleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 4rem 2rem 4rem;

  .copy {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0rem 14rem;
    color: #4c2f17;
    h2 {
      margin: 1rem 0rem;
      font-size: clamp(2rem, 6vw, 3rem);
    }
    &-description {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: inset 0px 0px 10px 2px rgba(182 182 182 / 80%);
      background: linear-gradient(to bottom, #f1f1f1 50%, #f1f1f1 50%, #d3d3d3 75%, #d3d3d3 75%);
      padding: 2rem;
      margin: 1rem 0rem;
      p {
        font-size: 1.2rem;
        line-height: 150%;
        &:not(:first-child) {
          padding: 1rem 0rem;
        }
      }
    }
  }
  .row {
    list-style: none;
    padding: 0;
    margin: 0;
    .column {
      padding: 1rem;
      min-height: 65vh;
      position: relative;
      .container {
        height: 100%;
        width: 100%;
        .photo {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: 2px solid #331d0b;
          cursor: pointer;
        }
        .badge {
          position: absolute;
          bottom: 0;
          right: 5px;
          display: flex;
          width: 90%;
          justify-content: flex-end;
          background: #331d0b;
          color: #fafafa;
          border: 2px solid #fafafa;
          p {
            padding: 0.5rem 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1rem;
            font-weight: 600;
            margin: 0;
          }
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    .row {
      .column {
        height: 50vh;
      }
    }
    padding: 0rem 0rem 2rem 0rem;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .copy {
      padding: 0rem 7rem;
    }
    .row .column {
      height: 55vh;
      .badge {
        bottom: 0.5rem;
        right: 5px;
      }
    }
    padding: 0rem 0rem 2rem 0rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .copy {
      padding: 0rem 3rem;
    }
    .row .column {
      height: auto;
    }
    padding: 0;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0;
    .copy {
      padding: 0rem 1.5rem;
      h2 {
        margin: 2rem 0rem 0.5rem 0rem;
      }
    }
  }
`

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
  }
`

export const CardPreview = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0;
  padding: 1rem 4rem;
  .previewPhoto {
    border: 4px solid #331d0b;
    overflow: hidden;
    height: 75vh;
    width: 85vw;
    img {
      height: 100%;
      width: 100%;
      background: #f1f1f1;
      object-fit: cover;
    }
  }
  .actions {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    a {
      border: 2px solid #331d0b;
      background: #f1f1f1;
      color: #331d0b;
      font-weight: bold;
      &:hover {
        background: #331d0b;
        border-color: #f1f1f1;
        color: #f1f1f1;
      }
      span {
        padding: 0rem 2rem;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .previewPhoto {
      width: 90vw;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0.5rem 1rem 1rem 1rem;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    .previewPhoto {
      width: 95vw;
    }
  }
`
