//Core
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
//Components
import App from './App'
import { I18nextProvider } from 'react-i18next'
// Store
import { store } from './store/init/store'
import i18n from './i18n'

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router>
        <App />
      </Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)
