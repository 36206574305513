import styled from 'styled-components'

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  height: 70px;
  padding: 0rem 4rem 0rem 0.5rem;
  transition: all 0.3s ease-out;
  .logo {
    position: absolute;
    left: 4rem;
    top: 0;
    height: 80px;
    cursor: pointer;
  }
  .links {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    a {
      font-weight: 600;
      color: #331d0b;
      text-decoration: none;
      padding: 0.5rem 1.5rem;
      text-align: center;
      font-family: 'Roboto';
      &.active {
        color: #4687ba;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 16px 0 16px;
    .logo {
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`
