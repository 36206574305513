import { motion } from 'framer-motion'
import { memo, useEffect } from 'react'
import { Variants } from '../../animations'

export const YouTubeFrame = memo(({ id }) => {
  useEffect(() => {
    document.addEventListener('touchstart', null, { passive: true })
    ;() => {
      document.removeEventListener('touchstart')
    }
  }, [])

  const { show } = Variants

  return (
    <>
      <motion.div
        variants={show()}
        layoutId={`frame-${id}`}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="preview"
      >
        <iframe
          referrerPolicy="same-origin"
          src={`https://www.youtube.com/embed/${id}`}
          title="YouTube video player"
          frameBorder="0"
          loading="lazy"
          allowFullScreen
        ></iframe>
      </motion.div>
    </>
  )
})
