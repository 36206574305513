import { Box, Typography } from '@material-ui/core'
import { AnimatePresence, motion } from 'framer-motion'
import { memo } from 'react'
import { Photo } from '.'
import { Variants } from '../../animations'

export const Content = memo(({ data }) => {
  const { title, ...rest } = data
  const { reveal } = Variants
  return (
    <AnimatePresence exitBeforeEnter>
      <Box component={motion.div} className="content" variants={reveal()} initial="hidden" animate="visible">
        <Box component="div" className="content-left">
          <Typography component="h2" variant="h2" className="content-left__title">
            {title}
          </Typography>
          <Photo className="content-left__logo" />
        </Box>
        <Box component="div" className="content-right">
          {rest?.copy1 && (
            <Typography component="p" align="left">
              {rest?.copy1}
            </Typography>
          )}

          {rest?.copy2 && (
            <Typography component="p" align="left">
              {rest?.copy2}
            </Typography>
          )}

          {rest?.copy3 && (
            <Typography component="p" align="left">
              {rest?.copy3}
            </Typography>
          )}

          {rest?.copy4 && (
            <Typography component="p" align="left">
              {rest?.copy4}
            </Typography>
          )}

          {rest?.copy5 && (
            <Typography component="p" align="left">
              {rest?.copy5}
            </Typography>
          )}

          {rest?.copy6 && (
            <Typography component="p" align="left">
              {rest?.copy6}
            </Typography>
          )}
          {rest?.copy7 && (
            <Typography component="p" align="left">
              {rest?.copy7}
            </Typography>
          )}
          {rest?.copy8 && (
            <Typography component="p" align="left">
              {rest?.copy8}
            </Typography>
          )}
          {rest?.copy9 && (
            <Typography component="p" align="left">
              {rest?.copy9}
            </Typography>
          )}
          {rest?.copy10 && (
            <Typography component="p" align="left">
              {rest?.copy10}
            </Typography>
          )}
          {rest?.copy11 && (
            <Typography component="p" align="left">
              {rest?.copy11}
            </Typography>
          )}
          {rest?.copy12 && (
            <Typography component="p" align="left">
              {rest?.copy12}
            </Typography>
          )}
          {rest?.copy13 && (
            <Typography component="p" align="left">
              {rest?.copy13}
            </Typography>
          )}
          {rest?.copy14 && (
            <Typography component="p" align="left">
              {rest?.copy14}
            </Typography>
          )}
          {rest?.copy15 && (
            <Typography component="p" align="left">
              {rest?.copy15}
            </Typography>
          )}
          {rest?.copy16 && (
            <Typography component="p" align="left">
              {rest?.copy16}
            </Typography>
          )}
        </Box>
      </Box>
    </AnimatePresence>
  )
})
