import { useDispatch } from 'react-redux'
import { requestAction } from '../store/actions/request'

export const useRequest = () => {
  const dispatch = useDispatch()
  const { setRequest } = requestAction
  const sendRequest = (payload) => dispatch(setRequest(payload))

  return { sendRequest }
}
