import { sidebar } from '../types'

const initialState = {
  isOpened: false,
}

export const sidebarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case sidebar.SET_SIDEBAR:
      return {
        isOpened: payload,
      }
    default:
      return { ...state }
  }
}
