import { createTheme } from '@material-ui/core'

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#331D0B',
    },
  },
  typography: {
    fontFamily: 'PT Serif',
    h2: {
      fontWeight: 'bold',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        borderBottom: '2px solid  #331D0B',
      },
    },
    MuiImageListItem: {
      root: {
        listStyle: 'none',
      },
      item: {
        height: '450px',
        zIndex: '200',
      },
      imgFullHeight: {
        width: '100%',
        heightL: '100%',
        objectFit: 'cover',
      },
    },
    MuiImageListItemBar: {
      root: {
        background: '#726f6d',
      },
    },
    MuiTabs: {
      flexContainer: {
        marginTop: '.5rem',
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        '&$selected': {
          color: '#331D0B',
        },
      },
    },
    MuiSelect: {
      root: {
        display: 'inline-flex',
        '&:hover': {
          borderBottom: 'none',
        },
      },
      icon: {
        display: 'none',
      },
      select: {
        padding: '16px !important',
        '&:focus': {
          background: 'none !important',
        },
      },
    },
    MuiMenu: {
      list: {
        padding: '0 !important',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*': {
          'scrollbar-width': 'thin',
        },
        '*::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#331D0B',
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1',
        },
        body: {
          overflowX: 'hidden',
          fontSize: 'initial',
          backgroundColor: '#d3d3d3',
        },
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#a69e96c7',
      },
    },
    MuiCardContent: {
      root: {
        padding: '0px 16px',
      },
    },
    MuiButton: {
      root: {
        color: '#331D0B',
        borderRadius: 0,
      },
    },
    MuiIconButton: {
      root: {
        color: '#331D0B',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 0,
      },
      paperWidthXl: {
        overflowX: 'hidden',
      },
    },
    MuiDialogTitle: {
      root: {
        display: 'flex',
        background: '#331D0B',
        color: '#f1f1f1',
      },
    },
  },
})
