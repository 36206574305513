import * as yup from 'yup'

const message = '*Обязательно к заполнению'

export const schema = yup.object().shape({
  name: yup.string().required(message),
  email: yup.string().email().required(message),
  phone: yup.string().min(6, '*Минимальное кол-во цифр: 6').max(15, '*Максимальное кол-во цифр: 15'),
  country: yup.string().required(message),
  city: yup.string().required(message),
  description: yup
    .string()
    .min(4, '*Минимально кол-во символов: 4')
    .max(10000, '*Привышен лимит ввода')
    .required(message),
})
