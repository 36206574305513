import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'
import { memo } from 'react'
import { Variants } from '../../animations'

export const Carousel = memo(({ images, page, direction }) => {
  const imageIndex = wrap(0, images?.length, page)
  const { slideLeft } = Variants
  return (
    <AnimatePresence initial={false} custom={direction}>
      <motion.img
        key={page}
        src={images[imageIndex]}
        custom={direction}
        variants={slideLeft(direction)}
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0 }}
        layoutId={`image-${images[imageIndex]}`}
        className="examples-preview__image"
      />
    </AnimatePresence>
  )
})
