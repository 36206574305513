import { useState } from 'react'
// Core
import { useSelector } from 'react-redux'
// Mui
import { FormControl, MenuItem, Select } from '@material-ui/core'
// Selctors
import { langSelector } from '../../store/selectors'
// Other
import { useLang } from '../../hooks'
import { LangModel } from '../../models'

import { ua, ru } from '../../assets/flags'

export const LangSelect = () => {
  const { language } = useSelector(langSelector)
  const { setLang } = useLang()
  const [open, setOpen] = useState(false)

  const handleChange = ({ target: { value } }) => {
    const { lang } = new LangModel(value)
    setLang(lang)
  }

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <FormControl>
      <Select
        labelId="open-select-label"
        id="open-select"
        value={language}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleChange}
        open={open}
      >
        <MenuItem value="UA">
          <img src={ua} alt="ukraine" height="24px" />
        </MenuItem>
        <MenuItem value="RU">
          <img src={ru} alt="russia" height="24px" />
        </MenuItem>
      </Select>
    </FormControl>
  )
}
