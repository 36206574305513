import { lang } from '../types'

const localStorageLang = localStorage.getItem('language')

const initialState = {
  language: localStorageLang ? localStorageLang : 'UA',
}

export const langReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case lang.SET_LANG:
      return { ...state, language: payload }
    default:
      return { ...state }
  }
}
