import { lang } from '../types'

export const checkLang = Object.freeze({
  selectLang: (language) => {
    localStorage.setItem('language', language)
    return {
      type: lang.SET_LANG,
      payload: language,
    }
  },
})
