import { forwardRef, memo } from 'react'
import { Box, IconButton, Slide, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Modal, Title } from '../../styles'
import CloseIcon from '@material-ui/icons/Close'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { config } from '../../helpers'
import { useLocation } from 'react-router'

const DialogTitle = ({ onClose, title }) => {
  return (
    <Title disableTypography>
      <Typography variant="h6">{title}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className="" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </Title>
  )
}

const DialogContent = ({ media }) => {
  const { pathname } = useLocation()
  const params = pathname.includes('/in-use')

  return (
    <Box className="modal-content">
      {media.protocols ? (
        <Slider {...config}>
          {media.protocols.map((item, index) => (
            <img src={item} key={index} alt="protocol" />
          ))}
        </Slider>
      ) : (
        <img src={media.img} alt="detail" className={params ? 'inUse' : ''} />
      )}
    </Box>
  )
}

export const Dialog = memo(({ content, onClose, open }) => {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)
  const { breakpoints } = useTheme()
  const small = useMediaQuery(breakpoints.down('xs'))
  return (
    <Modal
      TransitionComponent={Transition}
      fullScreen={small}
      fullWidth={small}
      maxWidth="xl"
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {open && (
        <Box className="detail">
          <DialogTitle onClose={onClose} title={content.name} />
          <DialogContent media={content} />
        </Box>
      )}
    </Modal>
  )
})
