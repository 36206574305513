import { Box } from '@material-ui/core'

export const TabPanel = ({ children, value, index }) => {
  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {value === index && <>{children}</>}
    </Box>
  )
}
