import styled from 'styled-components'
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { Box } from '@material-ui/core'

export const Card = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 200;
  img {
    border: 2px solid #331d0b;
    height: 450px;
    width: 100%;
    object-fit: cover;
  }
  .card-actions {
    display: flex;
    position: relative;
    bottom: 1.5rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: #331d0b;
    padding: 0rem 0rem 0rem 0.5rem;
    &__title {
      color: #f1f1f1;
      font-size: 1rem;
      font-weight: 600;
    }
    button {
      color: #f1f1f1;
      transition: 0.3s all ease;
      &:hover {
        color: #f1f1f1;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    img {
      height: 500px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    img {
      height: 400px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    img {
      height: 100%;
    }
  }
`

export const Dialog = styled(MuiDialog)`
  .detail {
    height: 100%;
    display: flex;
    flex-direction: column;
    &-action {
      justify-content: flex-end;
      align-items: center;
      button {
        width: 100%;
        color: #f1f1f1;
        background: #331d0b;
        border: 2px solid #331d0b;
        &:hover {
          color: #331d0b;
          background: #f1f1f1;
          border-color: #331d0b;
        }
      }
      ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 80vw;
        justify-content: center;
      }
      ${({ theme }) => theme.breakpoints.down('xs')} {
        width: auto;
      }
    }
  }
`
export const Title = styled(MuiDialogTitle)`
  display: flex;
  align-items: center;
  padding: 8px 20px;
  h6,
  p {
    flex: 1 auto;
  }
  button {
    color: #f1f1f1;
  }
`

export const Content = styled(MuiDialogContent)`
  .content {
    display: flex;
    align-items: center;
    margin: 0;
    height: 100%;
    color: #331d0b;
    &-media {
      padding-right: 24px;
      img {
        height: 400px;
        width: 100%;
        object-fit: cover;
      }
    }
    &-info {
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      p {
        font-weight: bold;
        font-size: 1.2rem;
      }
      span {
        color: #a3a3a3;
        font-weight: bold;
        font-style: italic;
      }
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      flex-direction: column;
      &-media {
        padding-right: 0;
        img {
          height: 350px;
        }
      }
    }
    ${({ theme }) => theme.breakpoints.down('xs')} {
      img {
        height: auto;
      }
    }
  }
`
export const Sort = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  color: #331d0b;
  width: 100%;
  margin-top: 0rem;
  padding: 0rem 1rem;
  z-index: 200;
  cursor: pointer;
  p {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .select-action {
    color: #331d0b;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`
