import { request } from '../types'
import { api } from '../../api'

const { sendRequest } = api

export const requestAction = Object.freeze({
  setRequest: (payload) => async (dispatch) => {
    dispatch({ type: request.SET_REQUEST })
    sendRequest(payload)
  },
})
