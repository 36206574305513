import { useDispatch, useSelector } from 'react-redux'
import { sidebarAction } from '../store/actions'
import { sidebarSelector } from '../store/selectors'

export const useNav = () => {
  const dispatch = useDispatch()
  const isOpened = useSelector(sidebarSelector)
  const setNav = (payload) => dispatch(sidebarAction(payload))

  return { setNav, isOpened }
}
