// Core
import { Fragment, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton, Divider, List, ListItem, ListItemText, Drawer as DrawerUi } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
// Actions
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks'

export const Drawer = () => {
  const navigate = useNavigate()
  const { setNav, isOpened } = useNav()
  const { t } = useTranslation()

  const handleDrawer = useCallback(() => setNav(true), [isOpened])

  const sidebarList = [
    {
      text: t('nav.main'),
      onClick: () => navigate('/home'),
    },
    {
      text: t('nav.production'),
      onClick: () => navigate('/products'),
    },
    {
      text: t('nav.porfolio'),
      onClick: () => navigate('/in-use'),
    },
    {
      text: t('nav.docs'),
      onClick: () => navigate('/documents'),
    },
    {
      text: t('nav.about'),
      onClick: () => navigate('/about-us'),
    },
    {
      text: t('nav.dealers'),
      onClick: () => navigate('/sales'),
    },
    {
      text: t('nav.contacts'),
      onClick: () => navigate('/contacts'),
    },
  ]

  const listJSX = sidebarList.map(({ text, onClick }) => {
    return (
      <Fragment key={text}>
        <ListItem component="li" button onClick={onClick}>
          <ListItemText>{text}</ListItemText>
        </ListItem>
        <Divider />
      </Fragment>
    )
  })

  return (
    <>
      <IconButton aria-label="open drawer" onClick={handleDrawer}>
        <Menu />
      </IconButton>
      <DrawerUi variant="persistent" anchor="left" open={isOpened}>
        <List>{listJSX}</List>
      </DrawerUi>
    </>
  )
}
