export class FormModel {
  constructor(name, phone, city, country, email, description) {
    this.name = name
    this.email = email
    this.phone = phone
    this.city = city
    this.country = country
    this.description = description
  }
}
