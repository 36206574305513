import { Box, List } from '@material-ui/core'
import styled from 'styled-components'

export const SaleWrapper = styled(Box)`
  display: flex;
  background: linear-gradient(to bottom, #ffffff, #f1f1f1 50%, #d3d3d3 75%, #d3d3d3 75%);
  .dealers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    img {
      position: absolute;
      height: 100%;
      z-index: 100;
      right: -12vw;
      opacity: 0.05;
      ${({ theme }) => theme.breakpoints.down('md')} {
        right: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    h1 {
      color: #462810;
      font-size: 3rem;
      padding: 2rem;
      font-weight: bold;
      z-index: 200;
    }
    &-action {
      padding: 4.5rem 0rem;
      z-index: 200;
      button {
        background: #f1f1f1;
        padding: 0.5rem 2rem;
        border: 2px solid #331d0b;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: #f1f1f1;
          background: #331d0b;
          border-color: #f1f1f1;
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    .dealers {
      &-action {
        padding: 1.5rem 0rem;
      }
    }
  }
`

export const DealersList = styled(List)`
  height: 55vh;
  z-index: 200;
  overflow: auto;
  width: 100%;
  cursor: pointer;
  padding: 1rem 2rem 0rem 2rem;
  &::-webkit-scrollbar {
    width: 0.2rem;
  }
  &::-webkit-scrollbar-track {
    background: #d3d3d3;
  }
  &::-webkit-scrollbar-thumb {
    background: #331d0b;
  }
  .dealer-list-card {
    display: flex;
    background: #f1f1f1;
    margin: 3rem 0rem;
    box-shadow: inset 0px 0px 15px 1px rgba(182 182 182 / 80%);
    transition: 0.3s all ease-in-out;
    padding: 0.5rem;
    &:hover {
      box-shadow: inset 0px 0px 20px 1px rgba(182 182 182 / 95%);
    }
    &:last-child,
    :first-child {
      margin: 0rem;
    }
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 40vh;
    padding: 1rem 1rem 0rem 1.5rem;
  }
`
