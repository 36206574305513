import { useDispatch } from 'react-redux'
import { checkLang } from '../store/actions'
import { useTranslation } from 'react-i18next'

export const useLang = () => {
  const { i18n, t } = useTranslation()
  const { selectLang } = checkLang
  const dispatch = useDispatch()

  const setLang = (payload) => {
    localStorage.setItem('language', payload)
    dispatch(selectLang(payload))
    i18n.changeLanguage(payload)
  }

  return { setLang, t }
}
