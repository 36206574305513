import Logo from '../../assets/logo/logo.svg';

export const Photo = ({ className }) => {
  return (
    <>
      <img
        src={Logo} 
        alt="leftSideImg"
        className={className}/>
    </>
  )
}

