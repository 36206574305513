import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import languageRU from './locales/ru/translation.json'
import languageUA from './locales/ua/translation.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      UA: languageUA,
      RU: languageRU,
    },
    lng: 'UA',
    fallbackLng: 'UA',
    debug: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
