//Core
import React, { useCallback, useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { CssBaseline, AppBar, Typography, Icon, useTheme, Container, Box } from '@material-ui/core'
import { Navigation } from '../Navigation'
import { Footer, Main } from '../../styles'
import { ThemeProvider } from 'styled-components'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import { useNav } from '../../hooks'

export const Layout = () => {
  const muiTheme = useTheme()
  const { pathname } = useLocation()
  const { setNav, isOpened } = useNav()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    })
  }, [pathname])

  const handleDrawer = useCallback(() => isOpened && setNav(false), [isOpened])

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Container disableGutters maxWidth="xl" onClick={handleDrawer}>
        <AppBar>
          <Navigation />
        </AppBar>
        <Main>
          <Outlet />
        </Main>
        <Footer>
          <Typography component="div" className="footer-copy">
            <Typography component="p">© «Екатеринославский кирпичъ»</Typography>
            <Typography component="span">2010-2021</Typography>
          </Typography>
          <Box component="ul">
            <Box component="li">
              <Typography component="a" href="https://m.facebook.com/EkatKirpich/" rel="noreferrer" target="_blank">
                <Icon>
                  <FacebookIcon />
                </Icon>
              </Typography>
            </Box>
            <Box component="li">
              <Typography
                component="a"
                href="https://www.instagram.com/hand.brick/?utm_medium"
                rel="noreferrer"
                target="_blank"
              >
                <Icon>
                  <InstagramIcon />
                </Icon>
              </Typography>
            </Box>
          </Box>
        </Footer>
      </Container>
    </ThemeProvider>
  )
}
