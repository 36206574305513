//Core
import { useState } from 'react'
import { Controller, useForm, useFormState } from 'react-hook-form'
// MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
// Сomponents
import { Input } from './Input'
// Other
import { useRequest } from '../../hooks'
import { isNumber, options } from '../../helpers'
import { StyledRequestButton } from '../../styles'
import { useTranslation } from 'react-i18next'

export const Request = () => {
  const { sendRequest } = useRequest()
  const { t } = useTranslation()
  const { control, handleSubmit, reset } = useForm(options)
  const { isDirty } = useFormState({ control })

  const submitForm = (callback) =>
    handleSubmit(({ name, phone, city, country, description, email }) => {
      const myRequest = `<b>Имя:</b> ${name}\n<b>Почта:</b> ${email}\n<b>Номер телефона:</b> ${phone}\n<b>Город:</b> ${city}\n<b>Страна:</b> ${country}\n<b>Описание:</b> ${description}`
      sendRequest(myRequest)
      reset()
      callback()
    })

  const [openDialog, setOpenDialog] = useState(false)
  const handleOpen = () => setOpenDialog(true)
  const handleClose = () => setOpenDialog(false)

  return (
    <>
      <Button onClick={handleOpen}>{t('request.action')}</Button>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openDialog}>
        <form onSubmit={submitForm(handleClose)}>
          <DialogTitle id="dialog-title">{t('request.title')}</DialogTitle>
          <DialogContent dividers>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label={t('request.name')}
                      value={value}
                      onChange={onChange}
                      error={error}
                      multiLine={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label={t('request.phone')}
                      value={value}
                      onChange={onChange}
                      isNumber={isNumber}
                      error={error}
                      multiLine={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label={t('request.email')}
                      value={value}
                      onChange={onChange}
                      error={error}
                      multiLine={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label={t('request.country')}
                      value={value}
                      onChange={onChange}
                      error={error}
                      multiLine={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label={t('request.city')}
                      value={value}
                      onChange={onChange}
                      error={error}
                      multiLine={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label={t('request.description')}
                      value={value}
                      onChange={onChange}
                      error={error}
                      multiLine={true}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <StyledRequestButton type="submit" variant="contained" disabled={!isDirty}>
              {t('request.send')}
            </StyledRequestButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
