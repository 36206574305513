import React from 'react'
import { TextField } from '@material-ui/core'

export const Input = ({ value, onChange, error, label, multiLine, isNumber }) => {
  return (
    <TextField
      label={label}
      fullWidth
      onKeyPress={isNumber ? isNumber : null}
      multiline={multiLine}
      variant="outlined"
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={error ? error.message : null}
    />
  )
}
